<template>
  <create-modal-layout
    :loading="loading"
    :success="success"
    :error="error"
    :mode="mode"
    :form-valid="formValid"
    :dialog-state.sync="dialogState"
    i18n-context="workspaces.bankAccounts"
    @form:submit="submitForm"
  >
    <template v-slot:form>
      <v-form
        v-show="!success && !error"
        ref="snippetForm"
        v-model="formValid"
        lazy-validation
      >
        <v-container fluid>
          <v-row>
            <v-col
              cols="12"
              md="6"
              class="mx-auto"
            >
              <card-layout>
                <template v-slot:title>
                  {{ $t('general.basicInfo') }}
                </template>
                <template v-slot:content>
                  <v-row>
                    <v-col
                      cols="12"
                      md="8"
                    >
                      <custom-text-field
                        v-model="acronym"
                        :label="$t('workspaces.bankAccounts.create.acronym') + ' *'"
                        :hint="$t('workspaces.bankAccounts.create.acronymHint')"
                        persistent-hint
                        :rules="[rules.required]"
                        clearable
                        autofocus
                        counter="128"
                        maxlength="128"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                    >
                      <custom-select
                        v-model="context"
                        :items="snippetContextList"
                        :label="$t('workspaces.businessCards.create.context') + ' *'"
                        :rules="[rules.required]"
                        required
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      class="text-body-2 text-uppercase blue-grey--text text--darken-3 py-4"
                    >
                      {{ $t('workspaces.bankAccounts.create.bankInfo') }}
                    </v-col>
                    <v-col cols="12">
                      <custom-text-field
                        v-model="form.bankName"
                        :label="$t('workspaces.bankAccounts.create.bankName') + ' *'"
                        :hint="$t('workspaces.bankAccounts.create.legal')"
                        persistent-hint
                        :rules="[rules.required]"
                        clearable
                        counter="128"
                        maxlength="128"
                      />
                    </v-col>
                    <v-col cols="12">
                      <custom-text-field
                        v-model="form.bankBranchName"
                        :label="$t('workspaces.bankAccounts.create.bankBranchName')"
                        :hint="$t('workspaces.bankAccounts.create.legal')"
                        persistent-hint
                        clearable
                        counter="128"
                        maxlength="128"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <custom-text-field
                        v-model="form.bic"
                        :label="$t('workspaces.bankAccounts.create.bic')"
                        :hint="$t('workspaces.bankAccounts.create.legal')"
                        persistent-hint
                        clearable
                        counter="128"
                        maxlength="128"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <custom-text-field
                        v-model="form.bankCountry"
                        :label="$t('workspaces.bankAccounts.create.bankCountry')"
                        :hint="$t('workspaces.bankAccounts.create.legal')"
                        persistent-hint
                        clearable
                        counter="128"
                        maxlength="128"
                      />
                    </v-col>
                  </v-row>
                </template>
              </card-layout>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="6"
              class="mx-auto"
            >
              <card-layout>
                <template v-slot:title>
                  {{ $t('general.basicInfo') }}
                </template>
                <template v-slot:content>
                  <v-row>
                    <v-col cols="12">
                      <custom-text-field
                        v-model="form.account"
                        :label="$t('workspaces.bankAccounts.create.account') + ' *'"
                        :hint="$t('workspaces.bankAccounts.create.legal')"
                        persistent-hint
                        :rules="[rules.required]"
                        clearable
                        counter="128"
                        maxlength="128"
                      />
                    </v-col>
                    <v-col cols="12">
                      <custom-text-field
                        v-model="form.iban"
                        :label="$t('workspaces.bankAccounts.create.iban') + ' *'"
                        :hint="$t('workspaces.bankAccounts.create.legal')"
                        persistent-hint
                        :rules="[rules.required]"
                        clearable
                        counter="128"
                        maxlength="128"
                      />
                    </v-col>
                    <v-col cols="12">
                      <custom-text-field
                        v-model="form.beneficieryName"
                        :label="$t('workspaces.bankAccounts.create.beneficieryName')"
                        :hint="$t('workspaces.bankAccounts.create.legal')"
                        persistent-hint
                        clearable
                        counter="128"
                        maxlength="128"
                      />
                    </v-col>
                  </v-row>
                </template>
              </card-layout>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </template>
  </create-modal-layout>
</template>

<script>
import { mapGetters } from 'vuex'
import { required } from '@/mixins/validation'
import { createDialogs } from '@/mixins/snippets'
import {
  dialogTriggers,
  dialogData
} from '@/mixins/dialogs'
import CreateModalLayout from '@/components/modals/CreateLayout'
import CardLayout from '@/components/layout/CardLayout'
import CustomTextField from '@/components/inputs/CustomTextField'
import CustomSelect from '@/components/inputs/CustomSelect'

export default {
  name: 'BankAccountsCreateDialog',
  components: {
    CreateModalLayout,
    CardLayout,
    CustomTextField,
    CustomSelect
  },
  mixins: [
    required,
    createDialogs,
    dialogTriggers,
    dialogData
  ],
  data () {
    return {
      category: 'bank',
      form: {
        bankCountry: null,
        bankName: null,
        bankBranchName: null,
        iban: null,
        bic: null,
        account: null,
        beneficieryName: null
      }
    }
  },
  computed: {
    ...mapGetters([
      'activeWorkspaceSnippetsCategoriesContext'
    ])
  },
  created () {
    this.$bus.on('open:bank-create-dialog', this.openInCreateMode)
  },
  beforeDestroy () {
    this.$bus.off('open:bank-create-dialog', this.openInCreateMode)
  },
  methods: {
    async openInCreateMode () {
      await this.resetState()
      this.dialogState = true
    },
    async openInEditMode (dialogData) {
      const data = { ...dialogData }
      await this.resetState()
      this.mode = 'edit'
      this.acronym = data.acronym
      this.context = data.context
      this.form = { ...data.card }
      this.id = data.id
      this.dialogState = true
    },
    async resetState () {
      if (this.$refs.snippetForm) {
        await this.$refs.snippetForm.reset()
      }
      this.mode = 'create'
      this.id = null
      this.error = false
      this.success = false
    }
  }
}
</script>
