<template>
  <component
    :is="emptyStateComponent"
    v-if="showCopyOrFlipEmptyState"
  />
  <create-layout-template
    v-else
    :key="$route.name"
  >
    <template v-slot:options>
      <create-form-options
        :is-copy-or-flip="isCopyOrFlip"
        :rules="rules"
        :active-workspace-pipelines="activeWorkspacePipelines"
        :selected-document-category.sync="selectedDocumentCategory"
        :pipeline.sync="pipeline"
        :pipeline-error.sync="pipelineError"
        :language.sync="language"
        :is-push-email.sync="isPushEmail"
        :recipients.sync="recipients"
        :recipients-error.sync="recipientsError"
      />
      <business-cards-create-dialog
        @refresh="getActiveWorkspaceSnippets"
      />
      <bank-accounts-create-dialog
        @refresh="getActiveWorkspaceSnippets"
      />
      <terms-create-dialog
        @refresh="getActiveWorkspaceSnippets"
      />
    </template>
    <template v-slot:form>
      <component
        :is="selectedDocumentCategory"
        :key="selectedDocumentCategory"
        :document-category="selectedDocumentCategory"
        :pipeline="pipeline"
        :pipeline-error.sync="pipelineError"
        :language="language"
        :is-push-email="isPushEmail"
        :recipients="recipients"
        :recipients-error.sync="recipientsError"
        :rules="rules"
        :active-workspace-pipelines="activeWorkspacePipelines"
        :my-companies="myCompanies"
        :client-companies="clientCompanies"
        :my-bank-accounts="myBankAccounts"
        :source="source"
        :is-copy-or-flip="isCopyOrFlip"
        :data-cy="`form-${selectedDocumentCategory}`"
        @update:invalid-form-warning="invalidFormWarning = $event"
      />
    </template>
    <template v-slot:snackbar>
      <create-snackbar :invalid-form-warning.sync="invalidFormWarning" />
    </template>
  </create-layout-template>
</template>

<script>
import * as snippets from '@/mixins/snippets'
import { createRules, validateEmail } from '@/mixins/validation'
import CreateLayoutTemplate from '@/components/create/CreateLayoutTemplate'
import CreateFormOptions from '@/components/create/CreateFormOptions'
import CreateSnackbar from '@/components/create/CreateSnackbar'
import CopyEmptyStates from '@/components/empty_states/documents/CopyEmptyStates'
import FlipEmptyStates from '@/components/empty_states/documents/FlipEmptyStates'
import CreatePlaceholder from '@/components/service/CreatePlaceholder'
import BusinessCardsCreateDialog from '@/components/modals/business_cards/Create'
import BankAccountsCreateDialog from '@/components/modals/bank_accounts/Create'
import TermsCreateDialog from '@/components/modals/terms/Create'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'DocumentsCreate',
  components: {
    CreateLayoutTemplate,
    CreateFormOptions,
    CreateSnackbar,
    CopyEmptyStates,
    FlipEmptyStates,
    BusinessCardsCreateDialog,
    BankAccountsCreateDialog,
    TermsCreateDialog,
    invoice: () => ({
      component: import('@/features/documents/components/create/Invoice'),
      loading: CreatePlaceholder
    }),
    creditNote: () => ({
      component: import('@/features/documents/components/create/Invoice'),
      loading: CreatePlaceholder
    }),
    debitNote: () => ({
      component: import('@/features/documents/components/create/Invoice'),
      loading: CreatePlaceholder
    }),
    proformInvoice: () => ({
      component: import('@/features/documents/components/create/Invoice'),
      loading: CreatePlaceholder
    }),
    purchaseOrder: () => ({
      component: import('@/features/documents/components/create/Order'),
      loading: CreatePlaceholder
    })
  },
  mixins: [
    snippets.getPartnerSnippets,
    snippets.myCompanies,
    snippets.myBankAccounts,
    snippets.clientCompanies,
    snippets.methodGetActiveWorkspaceSnippets,
    snippets.getActiveWorkspaceSnippets,
    createRules,
    validateEmail
  ],
  data () {
    return {
      invalidFormWarning: false,
      selectedDocumentCategory: null,
      pipeline: null,
      pipelineError: false,
      language: this.$i18n.locale,
      isPushEmail: false,
      recipients: [],
      recipientsError: false
    }
  },
  computed: {
    ...mapGetters([
      'activeWorkspacePipelines',
      'source',
      'sourceIsEmpty'
    ]),
    isCopyOrFlip () {
      return this.$route.name === 'DocumentsCopy' ||
      this.$route.name === 'DocumentsFlip'
    },
    showCopyOrFlipEmptyState () {
      return this.isCopyOrFlip && this.sourceIsEmpty
    },
    emptyStateComponent () {
      return this.$route.meta.emptyState
    }
  },
  watch: {
    activeWorkspacePipelines () {
      if (this.activeWorkspacePipelines.length === 1) this.pipeline = this.activeWorkspacePipelines[0].id
    }
  },
  mounted () {
    this.getActiveWorkspacePipelines()
    if (this.source.treatment && this.isCopyOrFlip) {
      this.selectedDocumentCategory = this.$route.params.category
      this.recipients = this.source.treatment.recipients
      this.language = this.source.treatment.language
      this.isPushEmail = this.source.treatment.isPushEmail
    }
  },
  methods: {
    ...mapActions([
      'getActiveWorkspacePipelines'
    ])
  }
}
</script>
