<template>
  <custom-autocomplete
    v-model="$attrs.value"
    :items="items"
    autocomplete="no-autocomplete"
    :label="`${$t('inputs.countryIso')} *`"
    :rules="[rules.required]"
    @input="$emit('input', $event)"
  />
</template>

<script>
import { countriesList } from '@/mixins/create/countries'
import { required } from '@/mixins/validation'
import CustomAutocomplete from '@/components/inputs/CustomAutocomplete'

export default {
  name: 'CountryCodeAutocomplete',
  components: { CustomAutocomplete },
  mixins: [countriesList, required],
  computed: {
    items () {
      return this.countries.map(countryCode => {
        return {
          value: countryCode,
          text: `${this.$t(`countries.${countryCode}`)} ( ${countryCode} )`
        }
      })
    }
  }
}
</script>
