<template>
  <v-row>
    <v-col
      cols="12"
      sm="6"
      md="4"
    >
      <custom-select
        :disabled="isCopyOrFlip"
        :prepend-icon="$vuetify.icons.values.document"
        :label="$t('documents.create.properties.documentCategory') + ' *'"
        :items="supportedDocuments"
        :value="selectedDocumentCategory"
        data-cy="input-category"
        @input="$emit('update:selected-document-category', $event)"
      />
    </v-col>
    <v-col
      cols="12"
      sm="6"
      md="4"
    >
      <custom-select
        :prepend-icon="$vuetify.icons.values.trendingDown"
        :items="activeWorkspacePipelines"
        item-text="name"
        item-value="id"
        :label="$t('documents.create.properties.pipeline') + ' *'"
        :rules="[rules.alwaysRequired]"
        :error="pipelineError"
        :value="pipeline"
        data-cy="input-pipeline"
        @input="$emit('update:pipeline', $event)"
      />
    </v-col>
    <v-col
      cols="12"
      sm="6"
      md="4"
    >
      <custom-select
        :prepend-icon="$vuetify.icons.values.language"
        item-text="label"
        item-value="value"
        :items="translatedLanguages"
        :hint="$t('documents.create.properties.languageHint')"
        persistent-hint
        :label="$t('documents.create.properties.language')"
        :rules="[rules.alwaysRequired]"
        :value="language"
        data-cy="input-language"
        @input="$emit('update:language', $event)"
      />
    </v-col>
    <v-col cols="12">
      <v-checkbox
        color="primary"
        :label="$t('documents.create.email.isPushEmail')"
        :value="isPushEmail"
        data-cy="input-email"
        @change="$emit('update:is-push-email', $event)"
      />
    </v-col>
    <v-slide-y-transition>
      <v-col
        v-if="isPushEmail"
        cols="12"
      >
        <custom-combobox
          :label="$t('documents.create.email.recipients') + ' *'"
          :hint="$t('documents.create.email.recipientsHint')"
          persistent-hint
          :prepend-icon="$vuetify.icons.values.email"
          :delimiters="[',', ';', ' ']"
          append-icon=""
          outlined
          multiple
          clearable
          chips
          deletable-chips
          :error="recipientsError"
          :value="recipients"
          data-cy="combobox-email"
          @input="$emit('update:recipients', $event)"
        >
          <template v-slot:selection="data">
            <v-chip
              :key="JSON.stringify(data.item)"
              close
              :input-value="data.selected"
              :disabled="data.disabled"
              @click:close="data.parent.selectItem(data.item)"
            >
              <v-avatar
                left
                :color="validateEmail(data.item) ? 'primary' : 'error'"
                class="primary white--text"
              >
                {{ data.item.slice(0, 1).toUpperCase() }}
              </v-avatar>
              {{ data.item }}
            </v-chip>
          </template>
        </custom-combobox>
      </v-col>
    </v-slide-y-transition>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import { validateEmail } from '@/mixins/validation'
import { recipientsAreValid } from '@/mixins/create/methods'
import CustomSelect from '@/components/inputs/CustomSelect'
import CustomCombobox from '@/components/inputs/CustomCombobox'

export default {
  name: 'CreateFormOptions',
  components: {
    CustomSelect,
    CustomCombobox
  },
  mixins: [
    validateEmail,
    recipientsAreValid
  ],
  props: {
    isCopyOrFlip: { type: Boolean, default: false },
    rules: { type: Object, default: () => { return {} } },
    activeWorkspacePipelines: { type: Array, default: () => { return [] } },
    selectedDocumentCategory: { type: String, default: '' },
    pipeline: { type: Number, default: null },
    pipelineError: { type: Boolean, default: false },
    language: { type: String, default: 'en' },
    isPushEmail: { type: Boolean, default: false },
    recipients: { type: Array, default: () => { return [] } },
    recipientsError: { type: Boolean, default: false }
  },
  data () {
    return {
      supportedLanguages: ['en', 'bg']
    }
  },
  computed: {
    ...mapGetters([
      'getSupportedDocuments'
    ]),
    translatedLanguages () {
      const languages = []
      for (const language of this.supportedLanguages) {
        languages.push({
          label: this.$t('general.' + language),
          value: language
        })
      }
      return languages
    },
    supportedDocuments () {
      return this.getSupportedDocuments.map(value => {
        return {
          text: this.$t('documentCategories.' + value),
          value: value
        }
      })
    }
  },
  watch: {
    recipients () {
      this.$emit('update:recipients-error', !this.recipientsAreValid())
    },
    pipeline (value) {
      this.$emit('update:pipeline-error', !value)
    }
  }
}
</script>
