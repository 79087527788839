import { mapGetters, mapActions } from 'vuex'
import api from '@/api/modules/workspaces/snippets'

export const getActiveWorkspaceSnippets = {
  mounted () {
    this.getActiveWorkspaceSnippets()
  }
}

export const getPartnerSnippets = {
  computed: {
    ...mapGetters([
      'allWorkspacePartnersSnippets',
      'allWorkspaceBanksSnippets'
    ])
  }
}

export const myCompanies = {
  computed: {
    myCompanies () {
      return this.allWorkspacePartnersSnippets.filter(partner => partner.context === 'me' || partner.context === 'shared')
    }
  }
}

export const clientCompanies = {
  computed: {
    clientCompanies () {
      return this.allWorkspacePartnersSnippets.filter(partner => partner.context === 'client' || partner.context === 'shared')
    }
  }
}

export const myBankAccounts = {
  computed: {
    myBankAccounts () {
      return this.allWorkspaceBanksSnippets.filter(bank => bank.context === 'me' || bank.context === 'shared')
    }
  }
}

export const methodGetActiveWorkspaceSnippets = {
  methods: {
    ...mapActions([
      'getActiveWorkspaceSnippets',
      'clearAllWorkspacesSnippets'
    ])
  }
}

export const setBankIfSingle = {
  watch: {
    myBankAccounts () {
      if (this.myBankAccounts.length === 1) {
        this.myBankAccount = this.myBankAccounts[0]
        this.$emit('setBankAccount', 'supplier', this.myBankAccount)
      }
    }
  }
}

export const createDialogs = {
  computed: {
    snippetContextList () {
      return this.activeWorkspaceSnippetsCategoriesContext.map(context => {
        return { text: this.$t('snippetContext.' + context), value: context }
      })
    }
  },
  methods: {
    async submitForm () {
      if (this.$refs.snippetForm.validate()) {
        this.loading = true
        const form = new FormData()

        if (this.id) form.append('id', this.id)
        form.append('category', this.category)
        form.append('context', this.context)
        form.append('acronym', this.acronym)
        form.append('json', JSON.stringify(this.form))

        try {
          const response = await api.submitSnippet(form, this.mode, this.id)
          if (response.status >= 200 && response.status < 300) {
            this.success = true
            this.$emit('refresh')
          } else {
            this.error = true
          }
        } catch (error) {
          this.error = true
        } finally {
          this.loading = false
        }
      }
    }
  }
}
