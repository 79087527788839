<template>
  <create-modal-layout
    :loading="loading"
    :success="success"
    :error="error"
    :mode="mode"
    :form-valid="formValid"
    :dialog-state.sync="dialogState"
    i18n-context="workspaces.businessCards"
    @form:submit="submitForm"
  >
    <template v-slot:form>
      <v-form
        v-show="!success && !error"
        ref="snippetForm"
        v-model="formValid"
        lazy-validation
      >
        <v-container fluid>
          <v-row>
            <v-col
              cols="12"
              md="6"
              class="mx-auto"
            >
              <card-layout>
                <template v-slot:title>
                  {{ $t('general.basicInfo') }}
                </template>
                <template v-slot:content>
                  <v-row>
                    <v-col
                      cols="12"
                      md="8"
                    >
                      <custom-text-field
                        v-model="acronym"
                        :label="$t('workspaces.businessCards.create.acronym') + ' *'"
                        :hint="$t('workspaces.businessCards.create.acronymHint')"
                        persistent-hint
                        :rules="[rules.required]"
                        clearable
                        autofocus
                        counter="128"
                        maxlength="128"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                    >
                      <custom-select
                        v-model="context"
                        :items="snippetContextList"
                        :label="$t('workspaces.businessCards.create.context') + ' *'"
                        :rules="[rules.required]"
                      />
                    </v-col>
                  </v-row>
                </template>
              </card-layout>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="6"
              class="mx-auto"
            >
              <card-layout>
                <template v-slot:title>
                  {{ $t('workspaces.businessCards.create.companyInfo') }}
                </template>
                <template v-slot:content>
                  <v-row>
                    <v-col cols="12">
                      <custom-text-field
                        v-model="form.name"
                        :label="$t('workspaces.businessCards.create.name') + ' *'"
                        :hint="$t('workspaces.businessCards.create.legal')"
                        persistent-hint
                        :rules="[rules.required]"
                        clearable
                        counter="128"
                        maxlength="128"
                      />
                    </v-col>
                    <v-col cols="12">
                      <custom-text-field
                        v-model="form.address"
                        :label="$t('workspaces.businessCards.create.street') + ' *'"
                        :hint="$t('workspaces.businessCards.create.legal')"
                        persistent-hint
                        :rules="[rules.required]"
                        clearable
                        counter="255"
                        maxlength="255"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <custom-text-field
                        v-model="form.city"
                        :label="$t('workspaces.businessCards.create.city') + ' *'"
                        :hint="$t('workspaces.businessCards.create.legal')"
                        persistent-hint
                        :rules="[rules.required]"
                        clearable
                        counter="128"
                        maxlength="128"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <custom-text-field
                        v-model="form.postalCode"
                        :label="$t('workspaces.businessCards.create.postalCode') + ' *'"
                        :hint="$t('workspaces.businessCards.create.legal')"
                        persistent-hint
                        :rules="[rules.required]"
                        clearable
                        counter="32"
                        maxlength="32"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="4"
                    >
                      <country-code-autocomplete
                        v-model="form.countryIso"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="8"
                    >
                      <custom-text-field
                        v-model="form.countryName"
                        :label="$t('workspaces.businessCards.create.countryName') + ' *'"
                        :hint="$t('workspaces.businessCards.create.legal')"
                        persistent-hint
                        :rules="[rules.required]"
                        clearable
                        counter="128"
                        maxlength="128"
                      />
                    </v-col>
                  </v-row>
                </template>
              </card-layout>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="6"
              class="mx-auto"
            >
              <card-layout>
                <template v-slot:title>
                  {{ $t('workspaces.businessCards.create.companyIdentification') }}
                </template>
                <template v-slot:content>
                  <v-row>
                    <v-col
                      cols="12"
                      class="py-0"
                    >
                      <v-chip-group
                        v-model="selectedRequiredIdentifiers"
                        column
                        active-class="primary--text"
                        mandatory
                        multiple
                      >
                        <template v-for="rid of getRequiredIdentifiers">
                          <v-chip
                            :key="'chip-' + rid"
                            :disabled="!!form[id]"
                            :value="rid"
                          >
                            {{ $t('supportedIdentifiers.' + rid) }}
                          </v-chip>
                        </template>
                      </v-chip-group>
                    </v-col>
                    <template v-for="sid of selectedRequiredIdentifiers">
                      <v-col
                        :key="'idCode-' + sid"
                        cols="12"
                        sm="6"
                      >
                        <custom-text-field
                          v-model="form[sid]"
                          outlined
                          :label="$t('supportedIdentifiers.' + sid) + ' *'"
                          :hint="$t('workspaces.businessCards.create.legal')"
                          persistent-hint
                          :rules="[rules.required]"
                          clearable
                          counter="255"
                          maxlength="255"
                        />
                      </v-col>
                    </template>
                  </v-row>
                </template>
              </card-layout>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="6"
              class="mx-auto"
            >
              <card-layout>
                <template v-slot:title>
                  {{ $t('workspaces.businessCards.create.optionalIdentification') }}
                </template>
                <template v-slot:content>
                  <v-row>
                    <v-col
                      cols="12"
                      class="py-0"
                    >
                      <v-chip-group
                        v-model="selectedOptionalIdentifiers"
                        column
                        active-class="primary--text"
                        multiple
                      >
                        <template v-for="oid of getOptionalIdentifiers">
                          <v-chip
                            :key="'chip-' + oid"
                            :disabled="!!form[oid]"
                            :value="oid"
                          >
                            {{ $t('supportedIdentifiers.' + oid) }}
                          </v-chip>
                        </template>
                      </v-chip-group>
                    </v-col>
                    <template v-for="soid of selectedOptionalIdentifiers">
                      <v-col
                        :key="'opCode-' + soid"
                        cols="12"
                        sm="6"
                      >
                        <custom-text-field
                          v-model="form[soid]"
                          outlined
                          :label="$t('supportedIdentifiers.' + soid)"
                          clearable
                          counter="255"
                          maxlength="255"
                        />
                      </v-col>
                    </template>
                  </v-row>
                </template>
              </card-layout>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="6"
              class="mx-auto"
            >
              <card-layout>
                <template v-slot:title>
                  {{ $t('workspaces.businessCards.create.companyContact') }}
                </template>
                <template v-slot:content>
                  <v-row>
                    <v-col cols="12">
                      <custom-text-field
                        v-model="form.personInCharge"
                        :label="$t('workspaces.businessCards.create.personInCharge')"
                        :hint="$t('workspaces.businessCards.create.legal')"
                        persistent-hint
                        clearable
                        counter="64"
                        maxlength="64"
                      />
                    </v-col>
                    <v-col cols="12">
                      <custom-text-field
                        v-model="form.email"
                        :label="$t('workspaces.businessCards.create.email')"
                        persistent-hint
                        :rules="[rules.emailFormat]"
                        clearable
                      />
                    </v-col>
                    <v-col cols="12">
                      <custom-text-field
                        v-model="form.phone"
                        :label="$t('workspaces.businessCards.create.phone')"
                        clearable
                        counter="32"
                        maxlength="32"
                      />
                    </v-col>
                    <v-col cols="12">
                      <custom-text-field
                        v-model="form.website"
                        :label="$t('workspaces.businessCards.create.website')"
                        :hint="$t('workspaces.businessCards.create.websiteHint')"
                        persistent-hint
                        clearable
                        counter="64"
                        maxlength="64"
                      />
                    </v-col>
                  </v-row>
                </template>
              </card-layout>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </template>
  </create-modal-layout>
</template>

<script>
import { mapGetters } from 'vuex'
import { validateEmail, required } from '@/mixins/validation'
import { countriesList } from '@/mixins/create/countries'
import { createDialogs } from '@/mixins/snippets'
import {
  dialogTriggers,
  dialogData
} from '@/mixins/dialogs'
import CreateModalLayout from '@/components/modals/CreateLayout'
import CardLayout from '@/components/layout/CardLayout'
import CustomTextField from '@/components/inputs/CustomTextField'
import CustomSelect from '@/components/inputs/CustomSelect'
import CountryCodeAutocomplete from '@/components/inputs/CountryCode'

export default {
  name: 'BusinessCardsCreateDialog',
  components: {
    CreateModalLayout,
    CardLayout,
    CustomTextField,
    CustomSelect,
    CountryCodeAutocomplete
  },
  mixins: [
    validateEmail,
    countriesList,
    createDialogs,
    dialogTriggers,
    dialogData,
    required
  ],
  data () {
    return {
      category: 'partner',
      selectedRequiredIdentifiers: [],
      selectedOptionalIdentifiers: [],
      form: {
        name: null,
        personInCharge: null,
        address: null,
        countryName: null,
        countryIso: null,
        city: null,
        postalCode: null,
        email: null,
        website: null,
        phone: null,
        vat: null,
        bulstat: null,
        gst: null,
        hst: null,
        sst: null,
        siren: null,
        siret: null,
        gln: null
      },
      rules: {
        emailFormat: value =>
          value
            ? this.validateEmail(value) || this.$t('general.rules.emailFormat')
            : true
      }
    }
  },
  computed: {
    ...mapGetters([
      'getRequiredIdentifiers',
      'getOptionalIdentifiers',
      'getAllIdentifiers',
      'activeWorkspaceSnippetsCategoriesContext'
    ])
  },
  created () {
    this.$bus.on('open:partner-create-dialog', this.openInCreateMode)
  },
  beforeDestroy () {
    this.$bus.off('open:partner-create-dialog', this.openInCreateMode)
  },
  methods: {
    setISOCountryCode (countryName) {
      this.form.countryIso = this.countriesList.find(country => country.text === countryName).value
    },
    assignIdentifiers () {
      this.selectedRequiredIdentifiers = this.getRequiredIdentifiers.filter(id => this.form[id])
      this.selectedOptionalIdentifiers = this.getOptionalIdentifiers.filter(id => this.form[id])
    },
    async openInCreateMode () {
      await this.resetState()
      this.dialogState = true
    },
    async openInEditMode (dialogData) {
      const data = { ...dialogData }
      await this.resetState()
      this.mode = 'edit'
      this.acronym = data.acronym
      this.context = data.context
      this.form = { ...data.card }
      this.id = data.id
      this.assignIdentifiers()
      this.dialogState = true
    },
    async resetState () {
      if (this.$refs.snippetForm) {
        await this.$refs.snippetForm.reset()
      }
      this.mode = 'create'
      this.id = null
      this.error = false
      this.success = false
      this.selectedOptionalIdentifiers = []
      this.selectedRequiredIdentifiers = []
    }
  }
}
</script>
