<script>
import { VCombobox } from 'vuetify/lib'

export default {
  name: 'CustomCombobox',
  extends: VCombobox,
  props: {
    outlined: { type: Boolean, default: true }
  }
}
</script>
