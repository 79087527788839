<template>
  <v-col
    cols="12"
    class="text-center py-5"
  >
    <v-avatar
      class="grey lighten-2"
      size="180"
    >
      <v-icon
        size="120"
        class="grey--text"
      >
        {{ $vuetify.icons.values.error }}
      </v-icon>
    </v-avatar>
    <div>
      <h1 class="text-h6 pt-5 grey--text">
        {{ $t('documents.copy.emptyStates.noSource') }}
      </h1>
      <p class="subheading py-2 grey--text">
        {{ $t('documents.copy.emptyStates.noSourceHelper') }}
      </p>
    </div>
  </v-col>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CopyEmptyStates',
  computed: {
    ...mapGetters([
      'showCopyEmptyState'
    ])
  }
}
</script>
