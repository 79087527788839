<template>
  <v-snackbar
    v-model="snackbarState"
    fixed
    rounded
    :timeout="6000"
    color="error"
  >
    {{ $t('general.formNotValid') }}
    <template v-slot:action="{ attrs }">
      <v-btn
        color="white"
        text
        rounded
        v-bind="attrs"
        @click="snackbarState = false"
      >
        {{ $t('general.close') }}
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: 'CreateSnackbar',
  props: {
    invalidFormWarning: { type: Boolean, default: false }
  },
  computed: {
    snackbarState: {
      get () {
        return this.invalidFormWarning
      },
      set (value) {
        this.$emit('update:invalid-form-warning', value)
      }
    }
  }
}
</script>
