<template>
  <v-container
    fluid
    class="pt-0"
  >
    <v-row>
      <v-col
        cols="12"
      >
        <v-toolbar
          flat
          class="transparent"
        >
          <v-toolbar-title>
            {{ $t('documents.create.generalSettings') }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card
          flat
          color="transparent"
        >
          <v-card-text class="text--primary">
            <slot name="options" />
          </v-card-text>
        </v-card>
        <v-slide-y-reverse-transition>
          <slot name="form" />
        </v-slide-y-reverse-transition>
        <slot name="snackbar" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'CreateLayoutTemplate'
}
</script>
